<template>
    <div :class="{ pdf: true, cht: currentLanguage === 'cht', rus: currentLanguage === 'rus' }">
        <slot />
    </div>
</template>
<script setup lang="ts">
const { currentLanguage } = useLanguages()
</script>
<style lang="scss">
:root {
    --bs-font-sans-serif: "Solido Book", sans-serif !important;
}

.pdf {
    font-family: "Solido Book", sans-serif !important;

    &.cht {
        font-family: "Solido Book Cht", sans-serif !important;
    }

    &.rus {
        font-family: "Solido Book Rus", sans-serif !important;
    }
}
</style>
